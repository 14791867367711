import React from "react";
import { Layout, Flex, Tabs } from "antd";
import { useSelector } from "react-redux";
import QuotesTable from "../components/QuotesTable";
import CustomerInformation from "../components/CustomerInformation";
import CustomerAccountsTable from "../components/CustomerAccountsTable";

const { Content } = Layout;

function CustomerAccount() {
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const mainTabList = ["Quotes", "Accounts"];

  return (
    <Layout
      style={{
        minHeight: "81vh",
        width: "82%",
        justifyContent: "center",
      }}
    >
      <Content style={{ padding: "20px" }}>
        <Flex gutter={[16, 16]} flex={""} style={{ flexDirection: "column" }}>
          <CustomerInformation customer={activeCustomer} />

          <Tabs
            style={{ marginTop: "5px" }}
            type="card"
            items={mainTabList.map((mainTabHeader) => {
              return {
                label: mainTabHeader,
                key: mainTabHeader,
                children:
                  mainTabHeader === "Quotes" ? (
                    <QuotesTable customer={activeCustomer} />
                  ) : (
                    <CustomerAccountsTable customer={activeCustomer} />
                  ),
              };
            })}
          />
        </Flex>
      </Content>
    </Layout>
  );
}

export default CustomerAccount;
