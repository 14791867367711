import API from "../store/utils/apiEndPoint";

// For agent to login
export const LoginAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/user/login`,
      {
        email: payload?.email,
        password: payload?.password,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// For agent to signup
export const SignupAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/user/register`,
      {
        email: payload?.email,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        password: payload?.password,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To create Quote and its respective Declaration form
export const CreateQuoteAndDeclarationAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(`/customer-accounts/quote/`, payload, config);
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To Update Quote
export const updateQuoteProposalAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.put(
      `/customer-accounts/quote/${payload?.quoteId}`,
      {
        quoteStatus: payload?.status,
        notes: payload?.notes,
        preferredProduct: payload?.preferredProduct,
        quoteDetails: payload?.quoteDetails,
        addedByUserId: payload?.addedByUserId,
        groupId: payload?.groupId,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To fetch particular Quote form
export const fetchQuoteAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.customerId}/quote/${payload?.quoteId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// For Adding Customer
export const addCustomer = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer`,
      {
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        phone: payload?.phoneNumber,
        email: payload?.email,
        addedByUserId: payload?.user_id,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch all Customers
export const fetchCustomers = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(`/customer-accounts/customers/`, config);
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch all Quotes of single customer
export const fetchCustomerQuoteListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.customerId}/quotes/`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};
